@import url("https://fonts.googleapis.com/css2?family=Gentium+Basic:wght@700&display=swap");
.App {
  color: #14ffec;
  background-color: #212121;
  font-family: "Gentium Basic", serif;
  text-transform: capitalize;
}
* {
  margin: 0%;
  padding: 0%;
}
@media screen and (max-width: 1000px) {
  #side-nav {
    display: none;
  }
}
@media screen and (min-width: 1000px) {
  #nav {
    display: none;
  }
}
/* width */
::-webkit-scrollbar {
  width: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #323232;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #0d7377;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #14ffec;
}
::selection {
  color: #323232;
  background: #14ffec;
}
.home-height {
  padding-top: 16px;
}
@media screen and (min-width: 1000px) {
  .home-height {
    height: 100vh;
  }
}
a {
  text-decoration: none;
  color: #14ffec;
}
a:hover {
  color: #ffffff;
}
@media screen and (min-width: 1000px) {
  .App {
    font-size: 18px;
  }
}
